import React from "react"
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle,} from "reactstrap"

import ReactCountryFlag from "react-country-flag"
import {IntlContext} from "../../../utility/context/Internationalization"
import Cookies from 'universal-cookie';
import keycloak from "../../../keycloak";
import axios from "axios";

const cookies = new Cookies();

let languageSettings = [{"locale": "ar", 'direction': 'rtl', 'lang': 'eg', 'country': 'العربية'}, {
    "locale": "en",
    'direction': 'ltr',
    'lang': 'us',
    'country': 'English'
}];
let locale = cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar';

class Lang extends React.PureComponent {


    state = {
        navbarSearch: false,
        langDropdown: false,
        country: cookies.get('country'),
        lang: cookies.get('lang'),
        suggestions: [],
    }
    saveLanguageOfUserSso = (locale, direction) => {
        cookies.set('locale_changed', true)
        cookies.set('locale',locale)
        cookies.set('language sso',locale)
        cookies.set('direction',direction)
        let params = {
            "user_sub": keycloak.tokenParsed.sub,
            "language": locale
        }

        axios.post(process.env.REACT_APP_API_BASE_URL + "/user/save_language", params)
            .then(response => {
                window.location.reload()
            })
    }

    handleLangDropdown = () =>
        this.setState({langDropdown: !this.state.langDropdown})

    render() {

        return (
            <ul className="nav navbar-nav navbar-nav-user float-right">
                <IntlContext.Consumer>
                    {context => {

                        return (
                            <Dropdown
                                tag="li"
                                className="dropdown-language nav-item"
                                isOpen={this.state.langDropdown}
                                toggle={this.handleLangDropdown}
                                data-tour="language"
                            >
                                <DropdownToggle
                                    tag="a"
                                    className="nav-link"
                                >
                                    {/*<ReactCountryFlag*/}
                                    {/*    className="country-flag"*/}
                                    {/*    countryCode={this.state.lang}*/}
                                    {/*    svg*/}
                                    {/*/>*/}
                                    <span className="d-sm-inline-block text-capitalize align-middle ml-50">
                   {this.state.country}
                  </span>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem
                                        tag="a"
                                        id="rtl-version"
                                        name="rtl-version"
                                        className='text-center'
                                        onClick={(e) => {
                                            if (cookies.get('locale') !== 'en') {
                                                this.saveLanguageOfUserSso('en', 'ltr')
                                            }
                                        }}
                                    >
                                        {/*<ReactCountryFlag className="country-flag" countryCode="us" svg/>*/}
                                        <span className="ml-1" style={{color: "#626262"}}>English</span>
                                    </DropdownItem>
                                    <DropdownItem
                                        tag="a"
                                        id="rtl-version"
                                        name="rtl-version"
                                        className='text-center'
                                        onClick={(e) => {
                                            if (cookies.get('locale') !== 'ar') {
                                                this.saveLanguageOfUserSso('ar', 'rtl')
                                            }
                                        }}
                                    >
                                        {/*<ReactCountryFlag className="country-flag" countryCode="eg" svg/>*/}
                                        <span className="ml-1 " style={{color: "#626262"}}>العربية</span>
                                    </DropdownItem>

                                </DropdownMenu>
                            </Dropdown>
                        )
                    }}
                </IntlContext.Consumer>


            </ul>
        )
    }
}

export default Lang
