import React from "react";
import * as Icon from "react-feather";
import OnlineCourse from '../views/pages/images/Online course.png'
import Writing from '../views/pages/images/writing (3).png'
import Increasing from '../views/pages/images/increasing (1).svg'
import StudentReading from '../views/pages/images/write (1).png'

const navigationConfig = [
    // {
    //     type: "groupHeader",
    //     groupTitle: "Variiance Courses"
    // },
    {
        id: "page3",
        title: "My Courses",
        type: "item",
        icon: <img src={OnlineCourse} width={18} style={{marginInlineEnd: '20px'}}/>,
        permissions: ["admin", "editor"],
        navLink: "/",
    },
    {
        id: "page6",
        title: "Instructions",
        type: "item",
        icon: <img src={Writing} width={18} style={{marginInlineEnd: '20px'}}/>,
        permissions: ["admin", "editor"],
        navLink: "/instructions",
    },
    {
        id: "page5",
        title: "Courses Analysis",
        type: "item",
        icon: <img src={Increasing} width={18} style={{marginInlineEnd: '20px'}}/>,
        permissions: ["admin", "editor"],
        navLink: "/courses-analysis",
    },
    {
        id: "page4",
        title: "Students",
        type: "item",
        icon: <img src={StudentReading} width={18} style={{marginInlineEnd: '20px'}}/>,
        permissions: ["admin", "editor"],
        navLink: "/students",
    },

    // {
    //     type: "groupHeader",
    //     groupTitle: "Variiance Teaching System"
    // },
];

export default navigationConfig;
