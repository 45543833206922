import React from "react"
import {CustomInput} from "reactstrap"
import {ContextLayout} from "../../../utility/context/Layout"
import "../../../assets/scss/components/customizer.scss"

class Customizer extends React.Component {


    render() {

        return (
            <ContextLayout.Consumer>
                {context => {
                    return (


                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="pt-25 m-1">Lang</h5>
                            <CustomInput
                                type="switch"
                                id="rtl-version"
                                name="rtl-version"
                                inline
                                checked={context.state.direction === "rtl"}
                                onChange={() => {
                                    if (context.state.direction === "rtl") {
                                        context.switchDir("ltr")
                                    } else {
                                        context.switchDir("rtl")
                                    }
                                }}
                            />
                        </div>

                    )
                }}
            </ContextLayout.Consumer>
        )
    }
}

export default Customizer
