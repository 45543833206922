import mock from "../mock"

let data = {
    events: [
        {
            id: 1,
            title: "presentation skills",
            start: "January 16, 2021",
            end: "January 16, 2021",
            label: "business",
            allDay: true,
            selectable: true
        }, {
            id: 2,
            title: "presentation skills",
            start: "January 17, 2021",
            end: "January 17, 2021",
            label: "business",
            allDay: true,
            selectable: true
        }, {
            id: 3,
            title: "presentation skills",
            start: "January 18, 2021",
            end: "January 18, 2021",
            label: "business",
            allDay: true,
            selectable: true
        }
    ]
}

// GET : Calendar Events
mock.onGet("/api/apps/calendar/events").reply(() => {
    return [200, data.events]
})
